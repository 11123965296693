import "slick-carousel"

document.addEventListener("turbolinks:load",initSlider)

function initSlider() {

  $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav'
  });
  $('.slider-nav').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    vertical: true,
    asNavFor: '.slider-for',
    arrows: false,
    dots: false,
    focusOnSelect: true,
    verticalSwiping: true,
    responsive: [{
        breakpoint: 992,
        settings: {
          vertical: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          vertical: false,
        }
      },
      {
        breakpoint: 580,
        settings: {
          vertical: false,
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 380,
        settings: {
          vertical: false,
          slidesToShow: 2,
        }
      }
    ]
  });

}
