import {Modal} from "bootstrap"

document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll(".room-plan-block").forEach((image, i) => {
    image.addEventListener("click", showImage);
  });
});

function showImage(){
  console.log(event.target);
  console.log(event.target.closest(".room-plan-block"));
  console.log(event.target.closest(".room-plan-block").querySelector("img"));
  const img = event.target.closest(".room-plan-block").querySelector("img").src
  const imageModal = document.getElementById("imageModal")
  document.getElementById("imgInImageModal").src = img

  const myModal = new Modal(imageModal,{})

  myModal.show()
}
