import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "bootstrap"

import "animate.css"
import {
  WOW
} from "wowjs"

new WOW().init();

import '../stylesheets/application'

import "./sliders"
import "./maps"
import "./image"

// import "@splidejs/splide";

import Inputmask from "inputmask";

function activeNavigation() {
  const currentUrl = document.URL,
    urlParts = currentUrl.split('#');
  if (urlParts.length > 1) {
    if (urlParts[1] === 'projects') {
      document.getElementById('navigation_projects').className = 'active';
      return
    }
    document.getElementById('navigation_contacts').className = 'active';
    return
  }
  if (currentUrl.includes('about_us')) {
    document.getElementById('navigation_about_us').className = 'active';
    return
  }
  if (currentUrl.includes('projects')) {
    document.getElementById('navigation_projects').className = 'active';
    return
  }

  document.getElementById('navigation_main').className = 'active';
}

document.addEventListener('turbolinks:load', function() {
  const hamburger = document.querySelector(".hamburger");
  const headerMenu = document.querySelector(".nav-links");
  hamburger.addEventListener("click", () => {
    headerMenu.classList.toggle("nav-opened");
    hamburger.classList.toggle("toggle");
  });

  activeNavigation();

  $(function() {
    Inputmask("+38(999) 999-9999").mask($("#phone"))
  });
})
