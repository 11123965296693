import Rails from "@rails/ujs";

let projectsMap = null
let projectMap = null
let buildedMarkers = [];
let buildingMarkers = [];
let last_opened_info_window = null;


document.addEventListener("turbolinks:load", function () {
    if (document.getElementById("projectsMap")) {
        if (true || projectsMap === null) {
            projectsMap = new google.maps.Map(document.getElementById("projectsMap"), {
                zoom: 14,
                center: {
                    lat: 48.6208,
                    lng: 22.2879
                },
            });
        }
        initProjectsMap(projectsMap)
        document.querySelectorAll(".select-markers").forEach((item, i) => {
            item.addEventListener("click", function () {
                document.querySelectorAll(".select-markers.active").forEach((button, i) => {
                    button.classList.remove("active")
                });
                item.classList.add("active")
                const target = item.dataset.target
                if (target === "all") {
                    showAllMarkers()
                    showAllProjects()
                } else if (target === "builded") {
                    showBuildedMarkers()
                    showBuildedProjects()
                } else if (target === "building") {
                    showBuildingMarkers()
                    showBuildingProjects()
                }
            });
        });

    } else if (document.getElementById("projectMap")) {
        initProjectMap()
    }
});

function showBuildedMarkers() {
    for (var i = 0; i < buildingMarkers.length; i++) {
        buildingMarkers[i].setMap(null);
    }
    for (var i = 0; i < buildedMarkers.length; i++) {
        buildedMarkers[i].setMap(projectsMap);
    }
}

function showBuildedProjects() {
    document.querySelectorAll("a.project[data-builded='false']").forEach(function (item, index) {
        item.classList.add("d-none")
    })
    document.querySelectorAll("a.project[data-builded='true']").forEach(function (item, index) {
        item.classList.remove("d-none")
    })
}

function showBuildingMarkers() {
    for (var i = 0; i < buildedMarkers.length; i++) {
        buildedMarkers[i].setMap(null);
    }
    for (var i = 0; i < buildingMarkers.length; i++) {
        buildingMarkers[i].setMap(projectsMap);
    }
}

function showBuildingProjects() {
    document.querySelectorAll("a.project[data-builded='true']").forEach(function (item, index) {
        item.classList.add("d-none")
    })
    document.querySelectorAll("a.project[data-builded='false']").forEach(function (item, index) {
        item.classList.remove("d-none")
    })
}

function showAllMarkers() {
    for (var i = 0; i < buildedMarkers.length; i++) {
        buildedMarkers[i].setMap(projectsMap);
    }
    for (var i = 0; i < buildingMarkers.length; i++) {
        buildingMarkers[i].setMap(projectsMap);
    }
}

function showAllProjects() {
    document.querySelectorAll("a.project[data-builded]").forEach(function (item, index) {
        item.classList.remove("d-none")
    })
}

function initProjectMap() {
    const buildedImage = new google.maps.MarkerImage('/builded.png',
        new google.maps.Size(64, 64),
    );
    const buildingImage = new google.maps.MarkerImage('/building.png',
        new google.maps.Size(64, 64),
    );

    const mapBlock = document.getElementById("projectMap");

    const latitude = parseFloat(mapBlock.dataset.latitude)
    const longitude = parseFloat(mapBlock.dataset.longitude)
    const img = mapBlock.dataset.img

    const map = new google.maps.Map(mapBlock, {
        zoom: 14,
        center: {
            lat: latitude,
            lng: longitude
        },
    });

    let icon = null
    if (mapBlock.dataset.builded === "true") {
        icon = buildedImage;
    } else {
        icon = buildingImage;
    }

    const marker = new google.maps.Marker({
        position: {
            lat: latitude,
            lng: longitude
        },
        map: map,
        icon: icon
    });

    let infowindow = new google.maps.InfoWindow({
        content: '<a href="#">' +
            '<div class="marker-link">' +
            '<div class="marker-img">' +
            '<img src="' + img + '">' +
            '</div>' +
            '<p>' + document.querySelector("h2").innerText + '</p>' +
            '</div>' +
            '</a>',
    });

    google.maps.event.addListener(marker, "click", () => {
        if (last_opened_info_window !== infowindow) {
            if (last_opened_info_window != null) last_opened_info_window.close();
            infowindow.open(map, marker);
            last_opened_info_window = infowindow;
        }
    });
}


function initProjectsMap(map) {
    buildedMarkers = [];
    buildingMarkers = [];

    const buildedImage = new google.maps.MarkerImage('/builded.png',
        new google.maps.Size(64, 64),
    );
    const buildingImage = new google.maps.MarkerImage('/building.png',
        new google.maps.Size(64, 64),
    );

    const projects = document.querySelectorAll("a.project") || []
    projects.forEach((project, i) => {
        const latitude = parseFloat(project.dataset.latitude)
        const longitude = parseFloat(project.dataset.longitude)
        const img = project.querySelector("img.project_image").src

        let marker = null
        if (project.dataset.builded === "true") {
            marker = new google.maps.Marker({
                position: {
                    lat: latitude,
                    lng: longitude
                },
                map: map,
                icon: buildedImage
            });
            buildedMarkers.push(marker)
        } else {
            marker = new google.maps.Marker({
                position: {
                    lat: latitude,
                    lng: longitude
                },
                map: map,
                icon: buildingImage
            });
            buildingMarkers.push(marker)
        }

        let infowindow = new google.maps.InfoWindow({
            content: '<a href="' + project.href + '">' +
                '<div class="marker-link">' +
                '<div class="marker-img">' +
                '<img src="' + img + '">' +
                '</div>' +
                project.querySelector(".projects-name").innerHTML +
                '</div>' +
                '</a>',
        });

        google.maps.event.addListener(marker, "click", () => {
            if (last_opened_info_window !== infowindow) {
                if (last_opened_info_window != null) last_opened_info_window.close();
                infowindow.open(map, marker);
                last_opened_info_window = infowindow;
            }
        });
    });
}
